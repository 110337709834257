import SectionLinks from "./SectionLinks";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import EpisodeRow from "./EpisodeRow";
import Section from ".//Section";
import "react-tabs/style/react-tabs.css";
import { ArrowCircleRightIcon } from "@heroicons/react/solid";
import { useEffect, useState } from "react";

export default function Program({
  sendPostMessage,
  program = null,
  newSeek,
  updateTime,
  streamProgress,
  streamPercent,
  duration,
  track,
  setTrack,
  playing,
  playAudio,
  buffering,
  name,
}) {
  const links = program.links ? JSON.parse(program.links) : [];
  const [tabIndex, setTabIndex] = useState(0);
  useEffect(() => {
    sendPostMessage();
  }, [tabIndex, sendPostMessage]);

  return (
    <div>
      {!name && program && program.name && (
        <h1 className="page-heading font-bold text-2xl md:text-4xl lg:text-5xl mb-8">
          {program.name}
        </h1>
      )}
      {program && (
        <div className="w-full gap-4 md:flex mb-8  show-meta">
          {program.image && program.image.webp && (
            <div className="w-full md:w-1/5 md:max-w-1/5 mb-4">
              <img
                src={program.image.webp.tile}
                alt={program.name}
                className={"w-full h-auto aspect-square"}
              />
            </div>
          )}
          <div className="md:flex-1 mb-4">
            {program && program.grid_one_liner && (
              <p className="text-lg">{program.grid_one_liner}</p>
            )}
            {program && program.introduction && (
              <p className="text-base">{program.introduction}</p>
            )}
            {program && program.presenter_string && (
              <p className="text-base">{program.presenter_string}</p>
            )}
            {links && <SectionLinks links={links} />}
          </div>
        </div>
      )}
      {program && (
        <Tabs selectedIndex={tabIndex} onSelect={(index) => setTabIndex(index)}>
          <TabList>
            {program && program.episodes && program.episodes.length > 0 && (
              <Tab>Episodes</Tab>
            )}
            {program && program.sections && program.sections.length > 0 && (
              <Tab>Segments</Tab>
            )}
            {program && <Tab>About</Tab>}
          </TabList>

          {program && program.episodes && program.episodes.length > 0 && (
            <TabPanel>
              <div className="p-2 bg-white">
                {program &&
                  program.episodes &&
                  program.episodes.map((episode, i) => (
                    <EpisodeRow
                      episode={{ ...episode, program: program }}
                      program={program}
                      key={`${i}e`}
                      updateTime={updateTime}
                      newSeek={newSeek}
                      buffering={buffering}
                      setTrack={setTrack}
                      playing={playing}
                      track={track}
                      index={i}
                      playAudio={playAudio}
                      streamProgress={streamProgress}
                      streamPercent={streamPercent}
                      duration={duration}
                      config={{
                        file: {
                          forceAudio: true,
                        },
                      }}
                    />
                  ))}
              </div>
            </TabPanel>
          )}
          {program && program.sections && program.sections.length > 0 && (
            <TabPanel>
              <div className="p-2 bg-white">
                {program &&
                  program.sections &&
                  program.sections.map((section, i) => (
                    <Section
                      section={{ ...section, program: program }}
                      updateTime={updateTime}
                      newSeek={newSeek}
                      buffering={buffering}
                      setTrack={setTrack}
                      playing={playing}
                      track={track}
                      index={i}
                      playAudio={playAudio}
                      streamProgress={streamProgress}
                      streamPercent={streamPercent}
                      duration={duration}
                      config={{
                        file: {
                          forceAudio: true,
                        },
                      }}
                      key={`${i}s`}
                    />
                  ))}
              </div>
            </TabPanel>
          )}
          <TabPanel>
            <div className="p-2 bg-white">
              {program && program.description && (
                <div
                  itemProp="description"
                  className="mb-8 mt-3 mb-3 text-sm html-content"
                  dangerouslySetInnerHTML={{
                    __html: program.description,
                  }}
                ></div>
              )}
            </div>
          </TabPanel>
        </Tabs>
      )}
    </div>
  );
}
